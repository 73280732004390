




































































import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import GenproxBadge from '@/components/layout/GenproxBadge.vue';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import { Report, ReportFile } from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";
import Utils, { UploadProgress } from '@/modules/shared/utils/utils';
import { BACKEND_BASE_URL } from '@/shared/consts';
import { ReportStatuses, UploadFileToReportDTO } from '../store/types';
import { AxiosRequestConfig } from 'axios';
import { UploadedFileInfo } from '@/modules/genprox/models/Shared';
import { Fund } from '@/modules/genprox/models/User';
import { Watch } from 'vue-property-decorator';
import { ArrowDownTrayIcon, ClockIcon, EyeIcon, PencilSquareIcon } from '@/assets/icons/heroicons/heroicons';
import GenproxDropdownMenu from '@/components/GenproxDropdownMenu.vue';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: { GenproxBadge, SygniRoundedButton, PencilSquareIcon, ClockIcon, GenproxDropdownMenu, ArrowDownTrayIcon, EyeIcon, simplebar, GenproxButton },
})
export default class RegRepBox extends Vue {
  readonly reportStatuses = ReportStatuses;
  readonly supportedFileFormats: Array<string> = ['doc', 'docx', 'pdf', 'xlsx', 'xls', 'xml'];

  @Prop({ default: false }) isFeatured: boolean;
  @Prop() report!: Report;
  @Prop() reports!: Report[];
  @Prop() openCardId!: string;
  @Prop() createdReport: number | null;
  reportYear: number | null = null;
  fundManagerData: Fund = {};
  uploadProgress: UploadProgress = { progress: 0 };
  reportGeneratingLoading: boolean = false;
  isOpen: boolean = false;
  isLoadingReport: boolean = false;
  editedReportId: number | null = null;
  selectedVersion: string = '';

  get isConfirmed(): boolean {
    return this.selectedReport.status === this.reportStatuses.GENERATED;
  }

  get routeType() {
    const routeType = this.$route?.path?.includes('/fund-manager') ? 'aifm' : this.$route?.path?.includes('/tools') ? 'tools' : 'fund' 

    return routeType
  }

  get reportOptions() {
    if(!this.reports) {
      return [];
    }

    return this.reports?.map((el: Report) => {
      return { label: this.$options.filters.leadingZeroDigitFormat(el.correctionNumber, 3), value: el.id };
    })?.sort((a: any, b: any) => b?.label - a?.label)
  }

  get selectedReport() {
    return this.reports?.find((el: Report) => el.id === this.selectedVersion);
  }

  get isReportReadyToCreate(): boolean {
    let isReady = true;
    this.selectedReport?.funds?.forEach(fund => {
      if (fund.inputFiles?.length > 0) {
        fund.inputFiles.forEach(inputFile => {
          if (!inputFile.category || inputFile.category === '') {
            isReady = false;
          }
        });
      } else {
        isReady = false;
      }
    });
    return isReady;
  }

  get hasPreview() {
    return (this.selectedReport?.data && (this.selectedReport?.status === ReportStatuses.GENERATED || this.selectedReport?.status === ReportStatuses.COMPLETED)) ? true : false;
  }

  getStatusLabel(status: string) {
    switch (status?.toUpperCase()) {
      case 'SENT-TO-ESPI':
        return 'Sent to ESPI'
      default:
        return this.$options.filters.capitalizeFirstLetter(status)
    }
  }

  getStatusClass(status: string) {
    switch (status?.toUpperCase()) {
      case 'GENERATED':
      case 'APPROVED':
        return 'orange'
      case 'DRAFT':
      case 'NEW':
        return 'green'
      default:
        return 'gn-primary'
    }
  }

  editDraftReport() {
    this.$emit('editDraftReport', this.selectedReport);
  }

  @Watch('openCard', { immediate: true }) onOpenCardChange() {
    if (this.selectedReport && this.selectedReport?.id === this.openCardId) {
      this.toggleOpen();
    }
  }

  mounted() {
    this.selectVersion();
  }
  
  closeOpen() {
    this.isOpen = false;
  }
  
  selectVersion(id?: string) {
    if (id) {
      this.selectedVersion = id
    } else {
      this.selectedVersion = this.reports?.sort((a, b) => b.correctionNumber - a.correctionNumber)[0]?.id;
    }
  }

  async toggleOpen(): Promise<void> {

    if (!(this.selectedReport?.funds?.length > 0)) {
      // this.isLoadingReport = true;
      try {
        const getContextDataRequest = this.$store.dispatch('genprox/getLeContextData', this.selectedReport?.fundManagerId);
        await this.$store.dispatch('regReporting/getReport', this.selectedReport?.id);
        this.fundManagerData = await getContextDataRequest;
      } catch (e) {
        e;
      }
      // this.isLoadingReport = false;
    }
    this.isOpen = !this.isOpen;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get isAdmin() {
    return !!(this.activeUserData?.role?.regulatoryReporting === 'admin')
  }

  async createReport(): Promise<void> {
    try {
      const action = this.isAdmin ? 'regReporting/generateReport' : 'regReporting/approveReport'
      const status = this.isAdmin ? ReportStatuses.GENERATED : ReportStatuses.APPROVED
      this.reportGeneratingLoading = true;
      await this.$store.dispatch(action, this.selectedReport?.id);
      this.$store.commit('regReporting/updateReport', { ...this.selectedReport, ...{ status } });
      this.isOpen = false;
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Upload success',
        text: 'Report has been successfully sent'
      });


    } catch (e) {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'An error occurred',
        text: this.$t(e.response.data.label).toString()
      });
    }
    this.reportGeneratingLoading = false;
  }

  async uploadFile(file: File, fund: Fund): Promise<void> {
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);

    try {
      const info: UploadedFileInfo = await this.$store.dispatch('genprox/uploadFile', { file, config });
      const fileToReportDTO: UploadFileToReportDTO = {
        payload: {
          fileId: info.id,
          category: null,
          fundId: fund.id
        },
        reportId: this.selectedReport.id
      }

      await this.$store.dispatch('regReporting/uploadReportFile', fileToReportDTO);
      await this.$store.dispatch('regReporting/getReport', this.selectedReport?.id);
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'File has been successfully added'
      });
    } catch (e) {
      e;
    }
  }

  async addFile(file: File[], fund: Fund): Promise<void> {
    if (file.length > 1) {
      for (let i = 0; i < file.length; i++) {
        this.uploadFile(file[i], fund);
      }
    } else {
      this.uploadFile(file[0], fund);
    }

    this.uploadProgress.progress = -1;
  }

  downloadFile(file: ReportFile): void {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.filePath}`, file.fileName);
  }

  isXmlReport(file: { fileName: string; }) {
    if (file?.fileName) {
      const fileFormat = (/[.]/.exec(file.fileName)) ? /[^.]+$/.exec(file.fileName)[0] : undefined
      return fileFormat === 'xml';
    }
    return false;
  }

  @Watch('reports', { deep: true, immediate: true }) onReportsChange() {
    this.selectVersion()
  }
}
