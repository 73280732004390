var render = function () {
  var _vm$selectedReport, _vm$selectedReport2, _vm$selectedReport3, _vm$selectedReport4, _vm$selectedReport5, _vm$selectedReport6, _vm$selectedReport7, _vm$selectedReport8, _vm$selectedReport9, _vm$selectedReport10, _vm$selectedReport11, _vm$selectedReport12, _vm$selectedReport13, _vm$selectedReport14, _vm$selectedReport15, _vm$selectedReport16, _vm$selectedReport17, _vm$selectedReport18, _vm$selectedReport19, _vm$selectedReport20, _vm$selectedReport21, _vm$selectedReport22, _vm$selectedReport23, _vm$selectedReport24, _vm$selectedReport25, _vm$selectedReport26, _vm$selectedReport27, _vm$selectedReport28, _vm$reportOptions, _vm$reportOptions2, _vm$selectedReport29, _vm$reportOptions3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['reg-rep-box', _vm.isFeatured ? 'reg-rep-box--featured' : '', ((_vm$selectedReport = _vm.selectedReport) === null || _vm$selectedReport === void 0 ? void 0 : _vm$selectedReport.status) === _vm.reportStatuses.DRAFT || ((_vm$selectedReport2 = _vm.selectedReport) === null || _vm$selectedReport2 === void 0 ? void 0 : _vm$selectedReport2.status) === _vm.reportStatuses.NEW ? 'reg-rep-box--draft' : '']
  }, [_c('div', {
    staticClass: "reg-rep-box__simplebar"
  }, [_c('div', {
    staticClass: "reg-rep-box__body"
  }, [_c('div', {
    staticClass: "reg-rep-box__header"
  }, [_c('div', {
    staticClass: "reg-rep-box__heading"
  }, [_c('p', {
    staticClass: "reg-rep-box__year"
  }, [_vm._v(_vm._s((_vm$selectedReport3 = _vm.selectedReport) === null || _vm$selectedReport3 === void 0 ? void 0 : _vm$selectedReport3.year))]), _c('p', {
    staticClass: "reg-rep-box__category"
  }, [_vm._v("KNF reporting")])]), _c('div', {
    staticClass: "reg-rep-box__status"
  }, [_c('GenproxBadge', {
    attrs: {
      "text": _vm.getStatusLabel((_vm$selectedReport4 = _vm.selectedReport) === null || _vm$selectedReport4 === void 0 ? void 0 : _vm$selectedReport4.status),
      "variant": _vm.getStatusClass((_vm$selectedReport5 = _vm.selectedReport) === null || _vm$selectedReport5 === void 0 ? void 0 : _vm$selectedReport5.status),
      "circle": true,
      "outline": true
    }
  })], 1)]), _c('div', {
    staticClass: "reg-rep-box__inner"
  }, [((_vm$selectedReport6 = _vm.selectedReport) === null || _vm$selectedReport6 === void 0 ? void 0 : _vm$selectedReport6.status) === _vm.reportStatuses.DRAFT || ((_vm$selectedReport7 = _vm.selectedReport) === null || _vm$selectedReport7 === void 0 ? void 0 : _vm$selectedReport7.status) === _vm.reportStatuses.NEW || ((_vm$selectedReport8 = _vm.selectedReport) === null || _vm$selectedReport8 === void 0 ? void 0 : _vm$selectedReport8.status) === _vm.reportStatuses.GENERATED || ((_vm$selectedReport9 = _vm.selectedReport) === null || _vm$selectedReport9 === void 0 ? void 0 : _vm$selectedReport9.status) === _vm.reportStatuses.APPROVED ? _c('div', {
    staticClass: "reg-rep-box__info"
  }, [_c('div', {
    staticClass: "reg-rep-box__icon"
  }, [((_vm$selectedReport10 = _vm.selectedReport) === null || _vm$selectedReport10 === void 0 ? void 0 : _vm$selectedReport10.status) === _vm.reportStatuses.DRAFT || ((_vm$selectedReport11 = _vm.selectedReport) === null || _vm$selectedReport11 === void 0 ? void 0 : _vm$selectedReport11.status) === _vm.reportStatuses.NEW ? _c('PencilSquareIcon') : _vm._e(), ((_vm$selectedReport12 = _vm.selectedReport) === null || _vm$selectedReport12 === void 0 ? void 0 : _vm$selectedReport12.status) === _vm.reportStatuses.GENERATED || ((_vm$selectedReport13 = _vm.selectedReport) === null || _vm$selectedReport13 === void 0 ? void 0 : _vm$selectedReport13.status) === _vm.reportStatuses.APPROVED ? _c('ClockIcon') : _vm._e()], 1), _c('div', {
    staticClass: "reg-rep-box__desc"
  }, [((_vm$selectedReport14 = _vm.selectedReport) === null || _vm$selectedReport14 === void 0 ? void 0 : _vm$selectedReport14.status) === _vm.reportStatuses.DRAFT || ((_vm$selectedReport15 = _vm.selectedReport) === null || _vm$selectedReport15 === void 0 ? void 0 : _vm$selectedReport15.status) === _vm.reportStatuses.NEW ? [_vm._v("You have a draft report already created.")] : _vm._e(), ((_vm$selectedReport16 = _vm.selectedReport) === null || _vm$selectedReport16 === void 0 ? void 0 : _vm$selectedReport16.status) === _vm.reportStatuses.GENERATED || ((_vm$selectedReport17 = _vm.selectedReport) === null || _vm$selectedReport17 === void 0 ? void 0 : _vm$selectedReport17.status) === _vm.reportStatuses.APPROVED ? [_vm._v("We are currently preparing your report"), _c('br'), _vm._v(" and will get back to you as soon as possible.")] : _vm._e()], 2), _c('div', {
    staticClass: "reg-rep-box__action"
  }, [((_vm$selectedReport18 = _vm.selectedReport) === null || _vm$selectedReport18 === void 0 ? void 0 : _vm$selectedReport18.status) === _vm.reportStatuses.DRAFT || ((_vm$selectedReport19 = _vm.selectedReport) === null || _vm$selectedReport19 === void 0 ? void 0 : _vm$selectedReport19.status) === _vm.reportStatuses.NEW ? _c('GenproxButton', {
    on: {
      "click": _vm.editDraftReport
    }
  }, [_vm._v("Return to edit")]) : _vm._e(), ((_vm$selectedReport20 = _vm.selectedReport) === null || _vm$selectedReport20 === void 0 ? void 0 : _vm$selectedReport20.status) === _vm.reportStatuses.GENERATED || ((_vm$selectedReport21 = _vm.selectedReport) === null || _vm$selectedReport21 === void 0 ? void 0 : _vm$selectedReport21.status) === _vm.reportStatuses.APPROVED ? _c('GenproxButton', {
    attrs: {
      "outline": true
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('openReportPreview', _vm.selectedReport);
      }
    }
  }, [_vm._v("Show form details")]) : _vm._e()], 1)]) : _vm._e(), ((_vm$selectedReport22 = _vm.selectedReport) === null || _vm$selectedReport22 === void 0 ? void 0 : _vm$selectedReport22.status) === _vm.reportStatuses.COMPLETED || ((_vm$selectedReport23 = _vm.selectedReport) === null || _vm$selectedReport23 === void 0 ? void 0 : _vm$selectedReport23.status) === _vm.reportStatuses.SENT_TO_ESPI ? _c('simplebar', {
    staticClass: "reg-rep-box__inner-files",
    attrs: {
      "data-simplebar-auto-hide": "false"
    }
  }, [_c('div', {
    staticClass: "files"
  }, [_c('ul', {
    staticClass: "files__list"
  }, _vm._l((_vm$selectedReport24 = _vm.selectedReport) === null || _vm$selectedReport24 === void 0 ? void 0 : _vm$selectedReport24.outputFiles, function (outputFile) {
    return _c('li', {
      key: outputFile.id,
      staticClass: "files__item"
    }, [_c('p', {
      staticClass: "files__name"
    }, [_vm._v(_vm._s(outputFile.fileName))]), _c('div', {
      staticClass: "files__actions"
    }, [_vm.isXmlReport(outputFile) ? _c('div', {
      staticClass: "files__action",
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            name: "".concat(_vm.routeType, "-reg-xml-preview"),
            query: {
              id: outputFile.fileId
            }
          });
        }
      }
    }, [_c('EyeIcon')], 1) : _vm._e(), _c('div', {
      staticClass: "files__action",
      on: {
        "click": function click($event) {
          return _vm.downloadFile(outputFile);
        }
      }
    }, [_c('ArrowDownTrayIcon')], 1)])]);
  }), 0)])]) : _vm._e()], 1), _c('div', {
    staticClass: "reg-rep-box__footer"
  }, [_c('div', {
    staticClass: "reg-rep-box__action"
  }, [(((_vm$selectedReport25 = _vm.selectedReport) === null || _vm$selectedReport25 === void 0 ? void 0 : _vm$selectedReport25.status) === _vm.reportStatuses.COMPLETED || ((_vm$selectedReport26 = _vm.selectedReport) === null || _vm$selectedReport26 === void 0 ? void 0 : _vm$selectedReport26.status) === _vm.reportStatuses.SENT_TO_ESPI) && (_vm$selectedReport27 = _vm.selectedReport) !== null && _vm$selectedReport27 !== void 0 && _vm$selectedReport27.data && ((_vm$selectedReport28 = _vm.selectedReport) === null || _vm$selectedReport28 === void 0 ? void 0 : _vm$selectedReport28.data) !== 'null' ? _c('GenproxButton', {
    attrs: {
      "outline": true
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('openReportPreview', _vm.selectedReport);
      }
    }
  }, [_vm._v("Show form details")]) : _vm._e()], 1), ((_vm$reportOptions = _vm.reportOptions) === null || _vm$reportOptions === void 0 ? void 0 : _vm$reportOptions.length) > 1 ? _c('div', {
    staticClass: "versions"
  }, [_c('p', {
    staticClass: "versions__label"
  }, [_vm._v("Versions:")]), ((_vm$reportOptions2 = _vm.reportOptions) === null || _vm$reportOptions2 === void 0 ? void 0 : _vm$reportOptions2.length) > 3 ? [_vm._l(_vm.reportOptions.slice(0, 3), function (report) {
    return _c('div', {
      key: report === null || report === void 0 ? void 0 : report.value,
      class: ['versions__el', (report === null || report === void 0 ? void 0 : report.value) === _vm.selectedVersion ? 'active' : ''],
      on: {
        "click": function click($event) {
          return _vm.selectVersion(report === null || report === void 0 ? void 0 : report.value);
        }
      }
    }, [_vm._v(_vm._s(report === null || report === void 0 ? void 0 : report.label))]);
  }), _c('div', {
    staticClass: "versions__label"
  }, [_c('GenproxDropdownMenu', {
    attrs: {
      "componentId": (_vm$selectedReport29 = _vm.selectedReport) === null || _vm$selectedReport29 === void 0 ? void 0 : _vm$selectedReport29.id,
      "label": "+".concat(((_vm$reportOptions3 = _vm.reportOptions) === null || _vm$reportOptions3 === void 0 ? void 0 : _vm$reportOptions3.length) - 3),
      "additionalDropdownClass": "gn-dropdown-menu--small",
      "size": "small",
      "activeOption": _vm.selectedVersion,
      "options": _vm.reportOptions.slice(3)
    },
    on: {
      "input": _vm.selectVersion
    }
  })], 1)] : _vm._l(_vm.reportOptions, function (report) {
    return _c('div', {
      key: report === null || report === void 0 ? void 0 : report.value,
      staticClass: "versions__el active",
      on: {
        "click": function click($event) {
          return _vm.selectVersion(report === null || report === void 0 ? void 0 : report.value);
        }
      }
    }, [_vm._v(_vm._s(report === null || report === void 0 ? void 0 : report.label))]);
  })], 2) : _vm._e()])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }