var render = function () {
  var _vm$groupedReports;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['reg-rep', _vm.formOpened ? 'reg-rep--blocked' : '']
  }, [_c('GenproxPageHeading', {
    attrs: {
      "title": "Regulatory reporting",
      "description": "Effortless reporting for regulatory needs"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('GenproxButton', {
          attrs: {
            "icon": _vm.PlusCircleIcon
          },
          on: {
            "click": _vm.openForm
          }
        }, [_vm._v("New report")])];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "reg-rep__container"
  }, [(_vm$groupedReports = _vm.groupedReports) !== null && _vm$groupedReports !== void 0 && _vm$groupedReports.length ? _vm._l(_vm.groupedReports, function (reports, index) {
    return _c('RegRepBox', {
      key: "reports-".concat(index, "-").concat(_vm.reRenderReports),
      ref: "reportCard".concat(index),
      refInFor: true,
      attrs: {
        "isFeatured": _vm.isFeatured(reports, index),
        "openCardId": _vm.openCardId,
        "createdReport": _vm.createdReport,
        "reports": reports
      },
      on: {
        "editDraftReport": _vm.editDraftReport,
        "openReportPreview": _vm.openReportPreview
      }
    });
  }) : [_c('GenproxDataPlaceholder', {
    attrs: {
      "header": "Your reports will appear here.",
      "description": "Once reports are generated, you'll see them in this section."
    }
  })]], 2), _vm.formOpened ? _c('report-form', {
    ref: "reportForm",
    attrs: {
      "reports": _vm.groupedReports,
      "reportId": _vm.reportId,
      "reportStatus": _vm.reportStatus
    },
    on: {
      "closeForm": _vm.closeForm,
      "reportCreated": _vm.refreshReports,
      "editDraftReport": _vm.editDraftReport,
      "openReportPreview": _vm.openReportPreview,
      "reportDraftCreated": _vm.handleReportDraftCreation
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }